import React from "react";

export default class CareUpdateForm extends React.Component {
	constructor(props) {
		super(props);
		this.iframeRef = React.createRef();
	}

	paramBuilder(data) {
		return `Campaign_Name=${
			data.campaignName.includes("Mammography")
				? "Breast Cancer Screening"
				: "Colorectal Screening"
		}&PCP_Name=${data.appointment.provider.name}&MRN=${
			data.patient.mrn
		}&DOB=${data.patient.dob}`;
	}
	render() {
		const { message, data } = this.props;

		return (
			message && (
				<div
					style={{
						margin: 0,
						padding: 0,
						height: "100vh",
						overflow: "hidden",
					}}>
					<iframe
						ref={this.iframeRef}
						src={`https://ohiohealth-forms.formstack.com/forms/care_update?${this.paramBuilder(
							data
						)}`}
						className="care-update"
						title="Care Update"
						style={{
							width: "100%",
							height: "100vh",
							border: "none",
						}}
					/>
				</div>
			)
		);
	}
}
