import { create } from 'apisauce'
const api = create({
  baseURL: window.mohWebServicesUrl
})

const websiteAPI = create({
  baseURL: window.location.origin
})

const mychartServices = create({
  baseURL: window.mychartURL,
  headers: { 'Accept': 'text/html' }
})

const epAppointmentAPI = create ({
  baseURL: window.epAppointmentLookupURL,
  headers: {'api_secret': window.epAPISecret, 'api_key': window.epAPIKey}
})

export { api, websiteAPI, mychartServices, epAppointmentAPI }
export default api
