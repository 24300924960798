import React from "react";
import "./../care-gap.scss";
import history, { listener } from "./../state/History";

class ThankYou extends React.Component {
	handleSubmit() {
		window.location.replace("/");
	}

	componentWillUnmount() {
		this.unlisten = history.listen(listener);
		this.unlisten();
	}

	render() {
		return (
			<div className="care-gap dob-modal">
				<img
					className="ohiohealth-logo"
					src="https://www.ohiohealth.com/siteassets/global/oh_logo_blue-200x52.png"
					alt="ohiohealth logo"
				/>
				<hr />
				<div className="container">
					<div className="content">
						<h4 className="care-gap-thank-you">
							<b>Submission successful!</b>
						</h4>
						<p>
							We're proud to be part of your health journey. Thank
							you <br />
							for trusting OhioHealth with your care.
							<br />
						</p>
						<br />
						<button
							onClick={this.props.onClick}
							className="return-home-page-button">
							Visit OhioHealth
						</button>
					</div>
				</div>
			</div>
		);
	}
}

export default ThankYou;
