import React from "react";
import SVGInline from "react-svg-inline";
import errorIcon from "../../../shared/assets/ic_error.svg";

export default class DateOfBirthInput extends React.Component {
	render() {
		const { dob, error, onChange, onClick } = this.props;
		return (
			<div className="care-gap dob-modal">
				<img
					className="ohiohealth-logo"
					src="https://www.ohiohealth.com/siteassets/global/oh_logo_blue-200x52.png"
					alt="ohiohealth logo"
				/>
				<hr></hr>
				<div className="container">
					<div className="content">
						<p>
							Before we get to the screening questionnaire, we{" "}
							<br></br> need to confirm your identity.
						</p>
						<label className="dob" for="input">
							Date of Birth
						</label>
						<input
							type="date"
							value={dob}
							onChange={onChange}
							className={error ? "error" : "input"}
						/>
						<button onClick={onClick} className="button">
							Submit
						</button>
						{error && error.message && (
							<div className="error-message">
								<SVGInline className="icon" svg={errorIcon} />
								<strong>Error:&nbsp;</strong>
								{error.message}
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}
