import { combineReducers } from "redux";
import { careGapReducer } from "./CareGapReducers";
import { connectRouter } from "connected-react-router";
import history from "./History";

const rootReducer = combineReducers({
	careGap: careGapReducer,
	router: connectRouter(history),
});

export default rootReducer;
