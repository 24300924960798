const initialState = {
	data: "",
	loading: false,
	message: "",
	error: null,
};

export function careGapReducer(state = initialState, action) {
	switch (action.type) {
		case "VERIFY_DOB":
			return {
				...state,
				loading: true,
				data: action.payload,
				message: "",
				error: null,
			};
		case "VERIFY_DOB_SUCCESS":
			return {
				...state,
				loading: false,
				data: action.payload,
				message: action.payload.campaignName,
				error: null,
			};
		case "VERIFY_DOB_FAILURE":
			return {
				...state,
				loading: false,
				data: "",
				message: "",
				error: action.payload,
			};
		default:
			return state;
	}
}
