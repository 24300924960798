import { race, delay } from "redux-saga/effects";
import { FETCH_PT_FROM_CGAPPTID_ENDPOINT_EP } from "../../../shared/constants/api-helpers";
import { LONG_TIMEOUT } from "../../../shared/constants/wait-times";
import { fetchAppointmentLookUpData } from "../../../shared/services/fetchData";

export function* verifyDOBRequest(id, dob) {
	const options = {
		appointmentId: id,
		dob: dob,
		endPoint: FETCH_PT_FROM_CGAPPTID_ENDPOINT_EP,
	};
	const { posts, timeout } = yield race({
		posts: fetchAppointmentLookUpData(options),
		timeout: delay(LONG_TIMEOUT),
	});
	if (
		posts &&
		posts.validationSuccessful &&
		posts.validationSuccessful === true
	) {
		return posts;
	} else {
		throw new Error("Date of birth does not match our records.");
	}
}
