import { connect } from "react-redux";
import { verifyDOB } from "./state/CareGapAction";
import CareGap from "./care-gap.jsx";

const mapStateToProps = (state, ownProps) => {
	return {
		loading: state.careGap.loading,
		data: state.careGap.data,
		message: state.careGap.message,
		error: state.careGap.error,
		apptId: ownProps.apptId,
	};
};

const mapDispatchToProps = {
	verifyDOB,
};

const mergeProps = (stateProps, dispatchProps) => {
	return {
		...stateProps,
		...dispatchProps,
	};
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CareGap);
