import React from "react";
import DateOfBirthInput from "./DateOfBirth/DateOfBirthInput.jsx";
import CareUpdateForm from "./CareUpdateForm/CareUpdateForm.jsx";

export default class CareGap extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			dob: {},
			apptId: this.props.apptId,
		};
		this.handleOnChange = this.handleOnChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleOnChange(e) {
		this.setState({ dob: e.target.value });
	}

	handleSubmit() {
		const { dob, apptId } = this.state;
		this.props.verifyDOB(apptId, dob);
	}

	render() {
		const { loading, data, message, error, navigateToThankYou } =
			this.props;
		return (
			<section>
				{!data ? (
					<DateOfBirthInput
						dob={this.state.dob}
						onClick={this.handleSubmit}
						onChange={this.handleOnChange}
						error={error}
					/>
				) : (
					<CareUpdateForm
						data={data}
						message={message}
						navigateToThankYou={navigateToThankYou}
					/>
				)}
			</section>
		);
	}
}
