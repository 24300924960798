import QuestionnaireModal from './QuestionnaireModal/QuestionnaireModal.jsx'
import LocationInfo from './LocationInfo/LocationInfo.jsx'
import MedEdBioData from './MedEdBioData/MedEdBioData.jsx'
import MultiLocationHeaderInfo from '../Blocks/MultiLocationMap/HeaderInfo.jsx'
import SiteSearch from '../SiteSearch/SiteSearch.jsx'
import LandingPageRoot from '../LandingPage/LandingPageRoot.jsx'
import Carousel from './Carousel'
import OnMyWayButton from './MultiLocationMap/OnMyWayButton.jsx'
import GetCareNowButton from './MultiLocationMap/GetCareNowButton.jsx'
import CommonlySearched from './CommonlySearched/CommonlySearchedBlock.jsx'
import {ScoredQuiz, CategoryQuiz} from './Quizzes'
import PromoBlock from '../Blocks/PromoBlock/PromoBlock.jsx'
import InsuranceSearch from './InsuranceSearch'
import ClassesSearch from './Classes'
import ClassesAndEvents from './ClassesAndEvents/ClassesAndEvents.jsx'
import StickyNav from './StickyNav/StickyNav.jsx'
import FirstAvailable from '../shared/components/FirstAvailable/FirstAvailable.jsx'
import WaitTimes from '../shared/components/WaitTimes/WaitTimes.jsx'
import CareGapLandingPage from './CareGap/CareGapLandingPage.jsx'

window.Carousel = Carousel
window.QuestionnaireModal = QuestionnaireModal
window.LocationInfo = LocationInfo
window.MedEdBioData = MedEdBioData
window.MultiLocationHeaderInfo = MultiLocationHeaderInfo
window.SiteSearch = SiteSearch
window.LandingPageRoot = LandingPageRoot
window.OnMyWayButton = OnMyWayButton
window.GetCareNowButton = GetCareNowButton
window.CommonlySearched = CommonlySearched
window.PromoBlock = PromoBlock
window.ScoredQuiz = ScoredQuiz
window.CategoryQuiz = CategoryQuiz
window.InsuranceSearch = InsuranceSearch
window.ClassesSearch = ClassesSearch
window.ClassesAndEvents = ClassesAndEvents
window.StickyNav = StickyNav
window.FirstAvailable = FirstAvailable
window.WaitTimes = WaitTimes
window.CareGap = CareGapLandingPage
