export const fetchContent = (id) => ({
	type: "FETCH_CONTENT",
	payload: { id },
});
export const setContent = (content) => ({
	type: "SET_CONTENT",
	payload: content,
});
export const verifyDOB = (id, dob) => ({
	type: "VERIFY_DOB",
	payload: { id, dob },
});
export const verifyDOBSuccess = (data) => ({
	type: "VERIFY_DOB_SUCCESS",
	payload: data,
});
export const verifyDOBFailure = (error) => ({
	type: "VERIFY_DOB_FAILURE",
	payload: error,
});
