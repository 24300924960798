import React from "react";
import { createStore, applyMiddleware } from "redux";
import { Switch, Route } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { routerMiddleware } from "connected-react-router";
import { Provider } from "react-redux";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import multi from "redux-multi";
import { watchContent, watchDOBVerification } from "./sagas/CareGapSagas.js";
import CareGapConnector from "./CareGapConnector.jsx";
import history from "./state/History.js";
import rootReducer from "./state/rootReducer.js";
import ThankYou from "./ThankYou/ThankYou.jsx";

export default class CareGapLandingPage extends React.Component {
	constructor(props) {
		super(props);
		const sagaMiddleware = createSagaMiddleware();
		const historyMiddleware = routerMiddleware(history);
		this.store = createStore(
			rootReducer,
			composeWithDevTools(
				applyMiddleware(sagaMiddleware, historyMiddleware, multi)
			)
		);

		sagaMiddleware.run(watchContent);
		sagaMiddleware.run(watchDOBVerification);
		this.handleReturn = this.handleReturn.bind(this);
		this.navigateToThankYou = this.navigateToThankYou.bind(this);
	}

	handleReturn() {
		window.location.replace("/");
	}

	navigateToThankYou(event) {
		setTimeout(() => {
			if (
				event.origin === "https://ohiohealth-forms.formstack.com" &&
				event.data === "onSubmit"
			) {
				history.push("/thankyou");
			}
		}, 1000);
	}

	componentDidMount() {
		window.addEventListener("message", this.navigateToThankYou);
	}

	componentWillUnmount() {
		window.removeEventListener("message", this.navigateToThankYou);
	}

	render() {
		return (
			<Provider store={this.store}>
				<ConnectedRouter history={history}>
					<Switch>
						<Route
							exact
							path="/"
							render={() => (
								<CareGapConnector
									apptId={this.props.ApptId}
									navigateToThankYou={this.navigateToThankYou}
								/>
							)}
						/>
						<Route
							path="/thankyou"
							render={() => (
								<ThankYou onClick={this.handleReturn} />
							)}
						/>
					</Switch>
				</ConnectedRouter>
			</Provider>
		);
	}
}
